<div class="container">
  <div class="container-login">
    <div class="background-img">
      <img
        alt="logo en1"
        height="140"
        ngSrc="../../../../../../../assets/login/logo.png"
        width="140" />
    </div>
    <div class="header">
      <img
        alt="Comlink"
        height="48"
        ngSrc="assets/login/comlink.png"
        width="200" />
      <span style="display: flex; justify-content: center; margin-top: 3rem">
        <hr />
      </span>
    </div>

    <div class="form">
      <ng-container [formGroup]="loginForm">
        <app-input
          formControlName="log"
          inputId="log"
          placeholder="Identifiant"
          rounded="true"
          type="text"
          width="extra-large" />
        <app-input
          formControlName="password"
          inputId="password"
          placeholder="Mot de passe"
          rounded="true"
          type="password"
          width="extra-large" />
      </ng-container>
    </div>
    <div class="footer">
      <hr />
      <app-btn
        (click)="onTryLogin()"
        [disabled]="loading"
        [isLoading]="loading"
        color="primary"
        size="large"
        text="Connexion">
      </app-btn>
      <small *ngIf="errorMessage" class="error-message">
        Vérifiez vos identifiants
      </small>
    </div>
  </div>
</div>
